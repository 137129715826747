import { matchesOrClosest } from '@ocodelib/ui-common';
import dayjs from 'dayjs';

export function isValidURL(str: string | null | undefined): boolean {
  if (typeof str !== 'string') return false;
  if (str.length === 0) return false;
  // const pattern = new RegExp(
  //   '^(https?:\\/\\/)?' + // protocol
  //     '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  //     '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  //     '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  //     '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  //     '(\\#[-a-z\\d_]*)?$',
  //   'i',
  // ) // fragment locator
  // return !!pattern.test(str)

  try {
    return Boolean(new URL(str));
  } catch (e) {
    return false;
  }
}

const queryElement = (
  element: React.Ref<HTMLElement | null | undefined> | HTMLElement | Document | null | undefined,
  selector: string,
): HTMLElement | null => {
  if (!element) return null;

  if (element instanceof HTMLElement) {
    return element.querySelector(selector) as HTMLElement | null;
  } else if (element instanceof Document) {
    return element.querySelector(selector) as HTMLElement | null;
  } else if (typeof element === 'object' && 'current' in element && element.current) {
    return element.current.querySelector(selector) as HTMLElement | null;
  }
  return null;
};

function doFocus(obj: any) {
  if (obj && 'focus' in obj && typeof obj['focus'] === 'function') {
    obj.focus();
  }
}

export const requestFocusSelector = (
  parent: React.Ref<HTMLElement | null | undefined> | HTMLElement | Document | null | undefined,
  selector: string,
  delay = -1,
) => {
  if (!parent) return;
  setTimeout(
    () => {
      const elem = queryElement(parent, selector);
      if (elem) {
        doFocus(elem);
      }
    },
    Math.max(delay, 0),
  );
};

export const requestSelector = (
  element: React.Ref<HTMLElement | null | undefined> | HTMLElement | Document | null | undefined,
  selector: string,
  callback: (elemnt: HTMLElement) => unknown,
  delay = -1,
) => {
  setTimeout(
    () => {
      const elem = queryElement(element, selector);
      if (elem) {
        callback(elem as HTMLElement);
      }
    },
    Math.max(delay, 0),
  );
};

export const requestFocusQuillEditor = (
  parent: React.Ref<HTMLElement | null | undefined> | HTMLElement | Document | null | undefined,
  editorParentSelector: string,
  delay = -1,
) => {
  if (!parent) return;

  const parentElem = queryElement(parent, editorParentSelector);
  if (!parentElem) return;

  const qlEditor = queryElement(parentElem, '.ql-editor');
  setTimeout(
    () => {
      doFocus(qlEditor);
    },
    Math.max(delay, 0),
  );
};

export function getScreenLeftTop(elem: HTMLElement) {
  let x = elem.offsetLeft;
  let y = elem.offsetTop;
  let cur = elem;
  while (cur.offsetParent) {
    const parent = cur.offsetParent as HTMLElement;
    x += parent.offsetLeft;
    y += parent.offsetTop;
    cur = parent;
  }
  return { x, y };
}

// 표시가능한 이미지 파일
export const isImageFile = (fileName: string | null | undefined): boolean => {
  if (!fileName) return false;
  return /.(jpg|jpeg|png|gif|jfif|bmp|svg|webp)$/i.test(fileName);
};

function filenameReservedRegex() {
  // eslint-disable-next-line no-control-regex
  return /[<>:"/\\|?*\u0000-\u001F]/g;
}

function windowsReservedNameRegex() {
  return /^(con|prn|aux|nul|com\d|lpt\d)$/i;
}

export const isValidFileName = (fileName: string): boolean => {
  if (!fileName || fileName.length > 255) {
    return false;
  }

  if (filenameReservedRegex().test(fileName) || windowsReservedNameRegex().test(fileName)) {
    return false;
  }

  if (fileName === '.' || fileName === '..') {
    return false;
  }

  return true;
};

export function formatDateOrNull(date: Date | number, format: string): string | null {
  try {
    return dayjs(date).format(format);
  } catch (ignore) {
    // empty
  }
  return null;
}

/**
 * 게시물의 시간 포매팅 함수
 * @param epochSeconds - epoch seconds
 * @returns
 */
export const formatEpochSeconds = (epochSeconds: number): [string, Date] => {
  const date = new Date(epochSeconds * 1000);
  const now = new Date();
  let fmt = '';
  if (date.getFullYear() === now.getFullYear()) {
    fmt = 'MM/DD HH:mm';
  } else {
    fmt = 'YYYY/MM/DD HH:mm';
  }

  const formattedStr = formatDateOrNull(date, fmt);
  if (formattedStr) {
    return [formattedStr, date];
  }
  return ['', date];
};

export const formatStrEpochSeconds = (epochSeconds: number): string => {
  const date = new Date(epochSeconds * 1000);
  const now = new Date();
  let fmt = '';
  if (date.getFullYear() === now.getFullYear()) {
    fmt = 'MM/DD HH:mm';
  } else {
    fmt = 'YYYY/MM/DD HH:mm';
  }

  const formattedStr = formatDateOrNull(date, fmt);
  if (formattedStr) {
    return formattedStr;
  }
  return '';
};

export function downloadUrlToFileId(url?: string | null): string | undefined {
  if (!url || url.length === 0) return undefined;

  // /p/file/download/ev_i_v01_2201192_363x200_png.png
  const u = new URL(url);
  let part = u.pathname;
  let idx = part.indexOf('/p/file/download2/');
  if (idx > 0) {
    part = part.substring(idx + '/p/file/download2/'.length);
  } else {
    idx = part.indexOf('/p/file/download/');
    if (idx > 0) {
      part = part.substring(idx + '/p/file/download/'.length);
    } else {
      alert('invalid url');
      return undefined;
    }
  }
  idx = part.indexOf('/');
  if (idx > 0) {
    part = part.substring(0, idx);
  }

  idx = part.indexOf('.');
  if (idx > 0) {
    part = part.substring(0, idx);
  }

  return part;
}

export function toggleTableRowSelectionByEventTarget(eventTarget: HTMLElement) {
  const tableRow = matchesOrClosest<HTMLTableRowElement>(eventTarget, 'tr');
  if (!tableRow) return;

  const table = tableRow.closest<HTMLTableElement>('table');
  if (!table) return;
  table.querySelectorAll<HTMLElement>('.x_selected').forEach((el) => {
    el.classList.remove('x_selected');
  });
  tableRow.classList.add('x_selected');
}

export function cancelCtx(ctx?: { canceled: boolean; cancel?: () => void }) {
  if (!ctx) return;
  if (ctx.canceled) return;
  ctx.canceled = true;
  ctx.cancel?.();
}

export const isTabKeyEvent = (e: React.KeyboardEvent): boolean => {
  return !e.shiftKey && e.key === 'Tab';
};

export const isEnterOrTabKeyEvent = (e: React.KeyboardEvent): boolean => {
  return e.key === 'Enter' || (!e.shiftKey && e.key === 'Tab');
};

export const isEnterKeyEvent = (e: React.KeyboardEvent): boolean => {
  return e.key === 'Enter';
};

export const isEscapeKeyEvent = (e: React.KeyboardEvent): boolean => {
  return e.key === 'Escape';
};

export function calcAbsoluteOffset(element: HTMLElement): [number, number] {
  let el: HTMLElement | null = element;
  let x = 0;
  let y = 0;
  do {
    x += el.offsetLeft;
    y += el.offsetTop;
    el = el.offsetParent as HTMLElement;
  } while (el);
  return [x, y];
}

/**
 * HTML 문자열에서 data URL을 추출하고 대체
 * @param htmlContent - html 문자열
 * @param uploadFunc - 업로드 함수
 * @returns
 */
export async function replaceDataUrls(
  htmlContent: string,
  uploadFunc: (dataUrl: string, index: number) => Promise<string>,
): Promise<string> {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');

  // data URL을 포함할 수 있는 태그와 속성
  const elements = doc.querySelectorAll('[src^="data:"], [href^="data:"]');

  let seq = 0;
  for (const element of elements) {
    let attrName: string;
    if (element.hasAttribute('src')) {
      attrName = 'src';
    } else if (element.hasAttribute('href')) {
      attrName = 'href';
    } else {
      continue;
    }

    const dataUrl = element.getAttribute(attrName)!;
    const uploadedUrl = await uploadFunc(dataUrl, seq++);
    // data URL을 업로드된 URL로 대체
    element.setAttribute(attrName, uploadedUrl);
  }

  // 변경된 HTML 문자열을 반환
  return doc.documentElement.outerHTML;
}
