import { Images } from '@cp949/web-image-util';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Gallery } from '@ocode/domain';
import { ApiRequestContext } from '@ocodelib/api-common';
import { isEnterOrTabKeyEvent, requestFocusSelector } from '@ocodelib/ui-common';
import { isBlank, limitMaxLength, strlen, trim } from '@ocodelib/util/string';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { CustomDialog } from '../../components/CustomDialog';
import { CustomDialogTitle } from '../../components/CustomDialogTitle';
import {
  IframeQuillEditorWrapper,
  type IframeQuillEditorType,
} from '../../iframes/IframeQuillEditorWrapper';
import { useApi } from '../../provider/useApi';
import { fixBbsSubstance, fixBbsSubstanceOrUndef } from '../../util/bbs-util';
import { handleError } from '../../util/handle-error';
import { replaceDataUrls } from '../../util/misc-utils';
import { YouTubeUtil } from '../../util/YouTubeUtil';

// 해시태그 최대 개수
const MAX_HASHTAGS = 10;

const INTRO_CONTENT_LEN = 250;
const TITLE_LEN = 60;

type InputData = {
  /**
   * 제목
   */
  title: string;

  /**
   * 소개글
   */
  introContent: string;

  /**
   * 유튜브 URL
   */
  youtubeUrl?: string;

  /**
   * 해시태그
   */
  hashtag?: string;
};

export interface GalleryEditDialogProps {
  open: boolean;
  onClose: (gallery?: Gallery) => void;
  galleryId: number;
}

export function GalleryEditDialog(props: GalleryEditDialogProps) {
  const { open, onClose, galleryId } = props;
  const api = useApi();
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [contentEditor, setContentEditor] = useState<IframeQuillEditorType>();
  const [inputData, setInputData] = useState<Partial<InputData>>({});
  const [tagList, setTagList] = useState<string[]>([]);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  // 콘텐트 에디터 에디터 초기 값
  const [editorInitialContent, setEditorInitialContent] = useState<string>();

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (!contentEditor || !editorInitialContent) return;
    contentEditor.setText(editorInitialContent);
  }, [editorInitialContent, contentEditor]);

  const requestFocus = useCallback((selector: string) => {
    requestFocusSelector(rootRef.current, selector, 0);
  }, []);

  const updateInputData = (part: Partial<InputData>) => {
    setInputData((prev) => ({ ...prev, ...part }));
  };

  const doLoadGallery = useCallback(
    async (ctx: ApiRequestContext, galleryId: number) => {
      setLoading(true);
      try {
        const { gallery } = await api.gallery.info({ ctx, galleryId });
        if (ctx.canceled) return;
        setInputData({
          title: gallery.title,
          introContent: gallery.introContent,
          youtubeUrl: gallery.youtubeUrl,
        });
        setTagList(trim(gallery.hashtag).split(','));
        setEditorInitialContent(gallery.content);
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    },
    [api],
  );

  useEffect(() => {
    const ctx = ApiRequestContext.of();
    doLoadGallery(ctx, galleryId);
    return () => {
      ctx.cancel();
    };
  }, [doLoadGallery, galleryId]);

  const doUploadDataUrl = useCallback(
    async (dataUrl: string, fileIndex: number): Promise<string> => {
      const imageFile = await Images.strings.toFile(dataUrl, 'img' + fileIndex);
      if (!imageFile) {
        throw new Error('data url parsing failed');
      }
      const { file: uploadedFile } = await api.gallery.uploadGalleryFile({
        file: imageFile,
        fileName: imageFile.name,
      });
      return uploadedFile.downloadUrl;
    },
    [api],
  );

  // 해시태그 삭제 버튼 클릭
  const handleClickTagDelete = (id: number) => {
    setTagList((prev) => prev.filter((_, idx) => idx !== id));
  };

  // 태그 등록하기
  const _onKeyDownTag = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ',' || event.key === ';') {
      if (isBlank(inputData.hashtag)) return;
      const tag = trim(inputData.hashtag);
      event.preventDefault();
      if (tagList.includes(tag)) {
        setInputData((prev) => ({ ...prev, hashtag: '' }));
      } else if (tagList.length < MAX_HASHTAGS) {
        setTagList((it) => it.concat([`${tag}`]));
        setInputData((prev) => ({ ...prev, hashtag: '' }));
      } else {
        alert(`해시태그는 최대 ${MAX_HASHTAGS}개까지 등록가능합니다`);
      }
    }
  };

  // 저장하기
  const doSave = useCallback(
    async (params: {
      galleryId: number;
      title: string;
      introContent: string;
      youtubeUrl?: string;
      hashtag?: string;
      content?: string;
    }): Promise<Gallery | null> => {
      try {
        setSaving(true);
        const newParams = { ...params };
        if (params.content) {
          newParams.content = await replaceDataUrls(params.content, (dataUrl, index) =>
            doUploadDataUrl(dataUrl, index),
          );
        }

        const { gallery } = await api.gallery.update(newParams);
        return gallery;
      } catch (err) {
        handleError(err);
      } finally {
        setSaving(false);
      }
      return null;
    },
    [api, doUploadDataUrl],
  );

  // 저장 버튼 클릭
  const handleClickSave = () => {
    if (!contentEditor) {
      // 이런 경우 없음
      console.warn('content editor not loaded');
      return;
    }

    const title = fixBbsSubstance(trim(inputData.title));
    const youtubeUrlInput = trim(inputData.youtubeUrl);
    updateInputData({ title });

    let youtubeUrl: string | undefined;
    if (title.length === 0) {
      toast.warn('제목을 입력해주세요');
      return;
    }

    if (title.length < 4) {
      toast.warn('제목을 최소 4글자로 입력해주세요');
      return;
    }

    if (youtubeUrlInput.length > 0) {
      youtubeUrl = YouTubeUtil.parse(youtubeUrlInput)?.shortUrl ?? undefined;
      if (!youtubeUrl) {
        toast.warn('유튜브 주소가 올바르지 않습니다');
        requestFocus('.GalleryEditDialog-youtubeUrl input');
        return;
      }
      updateInputData({ youtubeUrl });
    }

    const introContent = fixBbsSubstance(trim(inputData.introContent));
    const hashtag = tagList.join(',');
    contentEditor
      .getText()
      .then((content) =>
        doSave({
          galleryId,
          title: fixBbsSubstance(title),
          youtubeUrl,
          introContent,
          content: fixBbsSubstanceOrUndef(content ?? undefined),
          hashtag,
        }),
      )
      .then((success) => {
        if (success) {
          toast.success('저장되었습니다');
          onClose(success);
        }
      });
  };

  const { introContent } = inputData;

  return (
    <CustomDialog
      className="GalleryEditDialog-root"
      open={open}
      maxWidth="md"
      fullWidth
      onClose={handleClose}
    >
      <CustomDialogTitle onClose={handleClose}>GalleryEditDialog</CustomDialogTitle>

      <DialogContent dividers>
        <Box className="GalleryEditDialog-formControl" mt={3}>
          <TextField
            required
            fullWidth
            label="제목"
            disabled={saving}
            variant="outlined"
            value={inputData.title ?? ''}
            onChange={(e) => {
              updateInputData({ title: limitMaxLength(e.target.value, TITLE_LEN) });
            }}
            onKeyDown={(e) => {
              if (isEnterOrTabKeyEvent(e)) {
                e.preventDefault();
                requestFocus('.GalleryEditDialog-youtubeUrl');
              }
            }}
            helperText={
              <Typography component="span" textAlign="right" sx={{ display: 'block' }}>
                {strlen(inputData.title)} / {TITLE_LEN}
              </Typography>
            }
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2">짧은 소개글*</Typography>
          <TextField
            required
            fullWidth
            multiline
            minRows={2}
            maxRows={5}
            className="GalleryEditDialog-intro"
            variant="outlined"
            value={introContent ?? ''}
            onChange={(e) => {
              updateInputData({ introContent: limitMaxLength(e.target.value, INTRO_CONTENT_LEN) });
            }}
            helperText={
              <Typography component="span" textAlign="right" sx={{ display: 'block' }}>
                {strlen(introContent)} / {INTRO_CONTENT_LEN}
              </Typography>
            }
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2">유튜브 URL</Typography>
          <TextField
            fullWidth
            disabled={saving}
            variant="outlined"
            className="GalleryEditDialog-youtubeUrl"
            value={inputData.youtubeUrl ?? ''}
            onChange={(e) => {
              setInputData((prev) => ({ ...prev, youtubeUrl: e.target.value }));
            }}
            onKeyDown={(e) => {
              if (isEnterOrTabKeyEvent(e)) {
                e.preventDefault();
                requestFocus('.GalleryEditDialog-intro input');
              }
            }}
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2">상세 설명</Typography>
          <IframeQuillEditorWrapper
            className="ReactQuillEditorDemo-iframeEditor"
            onLoadEditor={(editor) => {
              setContentEditor(editor || undefined);
            }}
            sx={{
              position: 'relative',
              width: '100%',
              height: 300,
            }}
          />
        </Box>

        <Box className="GalleryEditer-formControl">
          <TextField
            fullWidth
            label={`해시태그 (최대 ${MAX_HASHTAGS}개)`}
            disabled={saving || tagList.length >= MAX_HASHTAGS}
            style={{ opacity: saving || tagList.length >= MAX_HASHTAGS ? 0.5 : 1 }}
            variant="outlined"
            value={inputData.hashtag ?? ''}
            placeholder="해시태그 입력 후 엔터(enter)"
            onChange={(e) => {
              setInputData((prev) => ({ ...prev, hashtag: e.target.value }));
            }}
            onKeyDown={_onKeyDownTag}
          />
        </Box>

        <Box
          className="GalleryEditDialog-tagBox"
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            width: '100%',
            maxWidth: 'md',
            mx: 'auto',
            px: 0.5,
            mt: 1,
          }}
        >
          {tagList.map((tag, idx) => (
            <Box
              className="GalleryEditDialog-tagItem"
              key={tag}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mr: 1,
                mt: 1,
                py: '2px',
                pl: 1,
                border: '1px solid #ddd',
                borderRadius: '4px',
                '& p': {
                  lineHeight: 0,
                },
                '& span': {
                  display: 'inline-block',
                  color: '#888',
                  mr: 0.5,
                },
              }}
            >
              <span>#</span>
              <p>{tag}</p>
              <IconButton
                size="small"
                onClick={() => {
                  handleClickTagDelete(idx);
                }}
              >
                <ClearIcon style={{ width: 14, height: 16 }} />
              </IconButton>
            </Box>
          ))}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClickSave} color="secondary">
          저장
        </Button>
        <Button onClick={handleClose}>취소</Button>
      </DialogActions>
    </CustomDialog>
  );
}
