export class YouTubeUtil {
  #videoId: string;

  constructor(videoId: string) {
    this.#videoId = videoId;
  }

  get videoId() {
    return this.#videoId;
  }

  /**
   * YouTube URL을 짧은 형태로 변환
   */
  get shortUrl(): string {
    return `https://youtu.be/${this.#videoId}`;
  }

  get thumbnailUrlDefault(): string {
    return this.#getThumbnailUrl('default');
  }

  get thumbnailUrlMedium(): string {
    return this.#getThumbnailUrl('mqdefault');
  }

  get thumbnailUrlHigh(): string {
    return this.#getThumbnailUrl('hqdefault');
  }

  get thumbnailUrlMax(): string {
    return this.#getThumbnailUrl('maxresdefault');
  }

  /**
   * 비디오 ID로 썸네일 URL 생성
   */
  #getThumbnailUrl(quality: 'default' | 'mqdefault' | 'hqdefault' | 'maxresdefault'): string {
    return `https://img.youtube.com/vi/${this.#videoId}/${quality}.jpg`;
  }

  static parse(urlOrVideoId: string): YouTubeUtil | null {
    // videoId의 길이는 11글자
    if (urlOrVideoId.length === 11) {
      return new YouTubeUtil(urlOrVideoId);
    }

    const videoId = YouTubeUtil.extractVideoId(urlOrVideoId);
    if (!videoId) {
      return null;
    }
    return new YouTubeUtil(videoId);
  }

  // 1. YouTube URL에서 비디오 ID 추출
  static extractVideoId(url: string): string | null {
    const regexes = [
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/|youtube\.com\/shorts\/)([^"&?\/\s]{11})/i,
    ];

    for (const regex of regexes) {
      const match = url.match(regex);
      if (match && match[1]) {
        return match[1]; // 비디오 ID 추출
      }
    }

    return null; // 매칭되지 않으면 null 반환
  }

  static isYouTubeUrl(url: string): boolean {
    if (url) {
      const videoId = YouTubeUtil.extractVideoId(url);
      return !!videoId;
    }
    return false;
  }
}

// // 사용 예시
// const urls = [
//   'https://www.youtube.com/watch?v=VIDEO_ID12345',
//   'https://youtu.be/VIDEO_ID12345',
//   'https://www.youtube.com/embed/VIDEO_ID12345',
//   'https://www.youtube.com/v/VIDEO_ID12345',
//   'https://www.youtube.com/watch?v=VIDEO_ID12345&feature=youtu.be',
//   'https://youtube.com/shorts/VIDEO_ID12345',
// ];
