import { Box, LinearProgress, Typography } from '@mui/material';
import type { UserAccountSimple } from '@ocode/domain';
import { ApiRequestContext } from '@ocodelib/api-common';
import { useCallback, useEffect, useState } from 'react';
import { DEFAULT_PROFILE_IMAGE_URL } from '../../../../config/code-config';
import { useApi } from '../../../../provider/useApi';
import { rootSx } from './style';
import { handleError } from '../../../../util/handle-error';

interface Props {
  accountId: number;
  onClose: () => void;
}

export function ProfileContentView(props: Props) {
  const { accountId, onClose } = props;
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [userAccount, setUserAccount] = useState<UserAccountSimple>();
  const api = useApi();

  const doLoad = useCallback(
    async (ctx: ApiRequestContext, accountId: number) => {
      setLoading(true);
      try {
        const { profile } = await api.userProfile.profileOther({ ctx, accountId });
        if (ctx.canceled) return;
        setUserAccount(profile);
      } catch (err: any) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    },
    [api],
  );

  useEffect(() => {
    const ctx = ApiRequestContext.of();
    doLoad(ctx, accountId);
    return () => {
      ctx.cancel();
    };
  }, [doLoad, accountId]);

  const { loginId, profileImageUrl } = userAccount || {};
  const userProfileImageUrl = profileImageUrl ?? DEFAULT_PROFILE_IMAGE_URL;

  return (
    <Box className="ProfileContentView-root" sx={rootSx}>
      <Box>
        {userAccount && (
          <div className="ProfileContentView-content">
            {!loading && (
              <>
                <Typography variant="h5" className="ProfileContentView-profileTitle">
                  {loginId || '이름없음'}
                </Typography>
                <div className="ProfileContentView-profileImage">
                  <img src={userProfileImageUrl} alt="" />
                </div>
              </>
            )}
          </div>
        )}

        <Typography
          variant={userAccount?.mood ? 'body2' : 'caption'}
          className="ProfileContentView-mood"
        >
          {userAccount?.mood || '소개글이 없습니다.'}
        </Typography>

        {(loading || saving) && (
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 30 }}>
            <LinearProgress color="secondary" />
          </div>
        )}
      </Box>
    </Box>
  );
}
