import { Box, ButtonBase, Stack, SxProps, Typography } from '@mui/material';
import type { GallerySimple } from '@ocode/domain';
import { flatSx } from '@ocodelib/ui-common';
import clsx from 'clsx';
import { UserProfileDialogOpenEvent } from '../events/user-profile-open';
import { BbsUserBox } from './BbsUserBox';
import {
  CountWithBookmarkIcon,
  CountWithEyeIcon,
  CountWithHeartIcon,
  CountWithReplyIcon,
} from './count-with-icon';
import { GalleryListBreedTag } from './GalleryListBreedTag';

interface Props {
  sx?: SxProps;
  gallery: GallerySimple;
  onClick?: React.MouseEventHandler;
  userHidden?: boolean;
}

// js와 py는 썸네일이 없으므로 이미지 위치에 아이콘을 표시
const THUMBNAILS: Record<string, string> = {
  js: '/_static/images/ic_javascript.png',
  py: '/_static/images/ic_python.png',
  p5: '/_static/images/ic_p5js.png', // p5js는 썸네일이 있어서 이 아이콘은 사용되지 않는다.
};

/**
 * TODO:
 * P5, JS, PY 공용으로 변경할 것
 */
export function GalleryListRow(props: Props) {
  const { gallery, userHidden = false, onClick, sx } = props;
  const {
    breed,
    thumbUrl,
    title,
    introContent,
    seenCount,
    chimCount,
    favorCount,
    replyCount,
    author,
    isFavor,
    isChim,
  } = gallery;

  return (
    <Box
      className="GalleryListRow-root"
      sx={flatSx(
        {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          px: 1.5,
          py: 1,
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
          },
        },
        sx,
      )}
    >
      <Box
        className={clsx({
          hasThumb: !!thumbUrl,
        })}
        sx={{
          '& img': {
            width: 130,
            height: 91,
            borderRadius: '10px',
            objectFit: 'cover',
            border: '1px solid #ddd',
          },
          '&.hasThumb img': {
            borderRadius: '10px',
            objectFit: 'cover',
            border: '1px solid #ddd',
          },
        }}
      >
        {/* <NextImage src="/_static/images/ic_javascript.png" width={48} height={48} alt="js icon" /> */}
        <img src={thumbUrl ?? THUMBNAILS[breed]} width={148} height={89} alt="" />
      </Box>

      <ButtonBase
        component="div"
        disableRipple
        onClick={onClick}
        sx={{
          display: 'block',
          ml: 2,
          minWidth: 200,
          flex: 1,
          '& .GalleryListRow-title': {
            color: '#222',
          },
          '& .GalleryListRow-desc': {
            color: '#888',
            mt: 0.5,
          },
        }}
      >
        <GalleryListBreedTag breed={breed} />
        <Typography
          sx={{
            width: '90%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          variant="subtitle1"
          className="GalleryListRow-title"
        >
          {title}
        </Typography>
        <Typography
          className="GalleryListRow-desc"
          sx={{
            width: '90%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {introContent}
        </Typography>
      </ButtonBase>

      <Box
        className="GalleryListRow-right"
        sx={{ display: 'flex', justifyContent: { xs: 'flex-end', md: 'flex-start' }, width: 260 }}
      >
        {!userHidden && (
          <Box sx={{ width: 120 }}>
            <BbsUserBox
              user={author}
              onClick={(e) => {
                e.stopPropagation();
                UserProfileDialogOpenEvent.emit(author.accountId);
              }}
            />
          </Box>
        )}
        <Stack sx={{ color: '#777' }} direction="row" spacing={1} alignItems="center">
          <CountWithEyeIcon count={seenCount} size="small" />
          <CountWithReplyIcon count={replyCount} size="small" />
          <CountWithHeartIcon count={favorCount} size="small" color="#888" active={isFavor} />
          <CountWithBookmarkIcon count={chimCount} size="small" color="#888" active={isChim} />
        </Stack>
      </Box>
    </Box>
  );
}
