import type { SxProps } from '@mui/material';

export const rootSx: SxProps = {
  position: 'relative',
  '& .ProfileContentView-content': {
    position: 'relative',
    py: 2,
    width: 290,
    minHeight: 200,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },

  '& .ProfileContentView-profileTitle': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '& .ProfileContentView-profileImage': {
    position: 'relative',
    width: 150,
    height: 150,
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    my: 2,
    mx: 0,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },

  '& .ProfileContentView-mood': {
    display: 'block',
    width: '100%',
    px: 1.5,
    mb: 1,
    mx: 0,
    textAlign: 'center',
    pb: 1.5,
    fontSize: '0.8rem',
  },
};
